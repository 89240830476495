import "./App.css";
import Game from "./pages/game";
import {
  BrowserRouter,
  // useRoutes,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "./pages/login";
import End from "./pages/end";
import { useEffect, useState } from "react";

const ProtectedRoute = ({ user, redirectPath = "/login" }) => {
  console.log("user :", user);
  //user verification (normaly with the server)
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

function App() {
  const [user, setUser] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("onefortree_user");
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });

  useEffect(() => {
    localStorage.setItem("onefortree_user", JSON.stringify(user));
  }, [user]);

  return (
    <div className="App">
      <div
        id="login2"
        className="div_login loginClass transformSlow"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: 10000,
          pointerEvents: "none",
        }}
      ></div>

      <div id="cl" className="div_login transform cloudLeft"></div>
      <div id="cr" className="div_login  transform cloudRight"></div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate to="game" />} />
          <Route
            path="login"
            element={<Login setUser={setUser} user={user} />}
          />
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="game" element={<Game user={user} />} />
          </Route>
          <Route path="end" element={<End />} />
          <Route path="*" element={<Navigate to="game" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
