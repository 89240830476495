import Robot from "./robot";
import { generateRandomType, generateRandomPos } from "./random.js"
import { updateAnimal } from "./animalsManager.js"
import robotNames from "../../assets/robotNames.json"

function routineRobot({ robots, mapData, map, player }) {
	setInterval(() => {
		for (let i = 0; i < robots.length; i++) {
			let position;
			if (Math.floor(Math.random * 100) % 2)
				position = generateRandomPos({ mapData });
			else
				position = tileToUpdate({ robot: robots[i], mapData })
			if (position !== null) {
				robots[i].moveTo({ x: position.x, y: position.y, tiles: mapData });
			}
		}
		map.updateChuncks({ player });
		updateAnimal({ mapData, map })
	}, 2500);
}

export function tileToUpdate({ robot, mapData }) {
	switch (robot.type) {
		case "laboureur":
			return (findTile({ robot, ground: "dry", mapData }));
		case "planteur":
			if (robot.needItem)
				return (findTile({ robot, ground: "berry", mapData }));
			else
				return (findTile({ robot, ground: "plowed", mapData }));
		case "arroseur":
			if (robot.needItem)
				return (findTile({ robot, ground: "water", mapData }));
			else
				return (findTile({ robot, ground: "seeded", mapData }));
		case "fertilizer":
			if (robot.needItem)
				return (findTile({ robot, ground: "fertilizer", mapData }));
			else
				return (findTile({ robot, ground: "watered", mapData }));
	}
	return null;
}

function findTile({ robot, ground, mapData }) {
	let x = Math.floor(robot.x / 60) + Math.floor(Math.random() * 5);
	let y = Math.floor(robot.y / 60) + Math.floor(Math.random() * 5);
	let step = 1;
	let numSteps = 1;
	let turnCounter = 1;
	let state = 0;
	if (x < 0 || x >= mapData.length)
		x = Math.floor(robot.x / 60);
	if (y < 0 || y >= mapData.length)
		y = Math.floor(robot.y / 60);
	while (!(x >= 0 && x < mapData.length && y >= 0 && y < mapData.length) || (mapData[x][y].ground !== ground && mapData[x][y].plant !== ground)) {
		switch (state) {
			case 0:
				x++;
				break;
			case 1:
				y--;
				break;
			case 2:
				x--;
				break;
			case 3:
				y++;
				break;
		}
		if (step % numSteps == 0) {
			state = (state + 1) % 4;
			turnCounter++;
			if (turnCounter % 2 == 0) {
				numSteps++;
			}
		}
		step++;
		if (step > 3600)
			return null;
	}
	return ({ x: x, y: y });
}

export function generateRobot({ nbRobot, mapData, resources, container, map, player, onEdit }) {
	let robots = [];
	let type;
	for (let i = 0; i < nbRobot; i++) {
		type = generateRandomType({ index: i, playerType: player.type, nbRobot });
		robots.push(new Robot(resources[type].spritesheet, false, "down", true, type, generateRandomPos({ mapData }), robotNames[i % robotNames.length], mapData));
		robots[i].zIndex = robots.y;
		container.addChild(robots[i]);
		robots[i].updatePlayer({ onEdit, map, mapData, resources: null });
	}
	routineRobot({ robots, mapData, map, player });
}