
export function generateRandomType({ index, playerType, nbRobot }) {
	let type = ["laboureur", "planteur", "arroseur", "fertilizer"];
	// return (type[index % 4]); // decomment for dev test with lot of robots
	if (playerType == null)
		return (type[index]);
	let i = 0;
	while (type[i] != playerType)
		i++;
	if (index == 0) {
		return (playerType)
	} else if (index >= 1 && index <= nbRobot / 3) {
		return (type[(i + 1) % 4])
	} else if (index > nbRobot / 3 && index <= 2 * nbRobot / 3) {
		return (type[(i + 2) % 4])
	} else {
		return (type[(i + 3) % 4])
	}
}

export function generateRandomPos({ mapData }) {
	let size = mapData.length;
	let position = {};
	position.x = Math.floor(Math.random() * size);
	position.y = Math.floor(Math.random() * size);
	while (mapData[position.x][position.y].ground === "water" || mapData[position.x][position.y].ground === "cloud") {
		position.x = Math.floor(Math.random() * size);
		position.y = Math.floor(Math.random() * size);
	}
	return position
}

export function findRandomGrassTile({ mapData, map, new_type }) {
	let x = Math.floor(Math.random() * (mapData.length + 1));
	let y = Math.floor(Math.random() * (mapData.length + 1));

	for (let i = y; i < mapData.length; i++) {
		for (let j = x; j < mapData.length; j++) {
			if (mapData[i][j].ground == "grass" && mapData[i][j].plant == "none") {
				mapData[i][j].plant = new_type;
				let tile_to_update = map.getTile({ x: i, y: j });
				if (tile_to_update !== false)
					tile_to_update.updateTexture({ tileData: mapData[i][j], tilePosition: tile_to_update.position })
				return ({ i, j });
			}

			if (i == mapData.length - 1 && j == mapData.length - 1) {
				console.log("AAAHAHHAHAHA", x, y);
				i = 0;
				j = 0;
				x = 0;
			}
		}
	}
	return { x, y };
}

export function countNbTotalTree({ mapData }) {
	let nbTotaltree = 0;
	for (let i = 0; i < mapData.length; i++) {
		for (let j = 0; j < mapData.length; j++) {
			if (mapData[i][j].ground !== "grass" && mapData[i][j].ground !== "water" && mapData[i][j].ground !== "tree" && mapData[i][j].ground !== "cloud")
				nbTotaltree++;
		}
	}
	return nbTotaltree;
}