import React, { useEffect } from 'react'
import './end.css'
import { useNavigate } from "react-router-dom";

function End() {
  const navigate = useNavigate();
  const replay = () => {
        navigate("/login")
        window.location.reload(false);
}

  let audio = new Audio("/poufsoufle.mp3");
  useEffect(()=>{
    // let audio = new Audio(poufsoufle);
    setTimeout(()=>{
      audio.play();
    }, 1000);
  },[audio])

  return (
    <div className='end-text'>
      <p>OMG TA GAGNER</p>
      <button type="button" onClick={replay}><strong>Rejouer</strong></button>
    </div>
  )
}

export default End