import matchers from "@testing-library/jest-dom/matchers";
import * as PIXI from "pixi.js";
import { countNbTotalTree } from "./random";

const spriteSize = 60;

class Tile {
	constructor({ position, container, onClic, resources, playerType }) {
		this.sprites = [];
		this.playerType = playerType;
		this.position = position;
		this.resources = resources;
		let types = ["ground", "plant", "animal"];
		for (let k = 0; k < types.length; k++) {
			let sprite = new PIXI.AnimatedSprite(
				this.resources["ground"].spritesheet.animations["water"]
			);
			this.sprites.push(sprite);
			sprite.anchor.set(0.5);
			sprite.position = this.position;
			// sprite.width = spriteSize
			// sprite.height = spriteSize
			sprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;

			if (types[k] === "ground") {
				sprite.zIndex = -100;
				sprite.interactive = true;
				sprite.on("pointerdown", (e) => {
					onClic({
						x: this.position.x,
						y: this.position.y,
					});
				});
			} else if (types[k] === "plant") {
				sprite.anchor.set(0.5);
				sprite.zIndex = 0;
			}
			container.addChild(sprite);
		}
	}

	ground() {
		return this.sprites[0];
	}
	plant() {
		return this.sprites[1];
	}
	animal() {
		return this.sprites[2];
	}

	updateTexture({ tileData, tilePos }) {
		if (tilePos) this.position = tilePos;

		let textures = [
			this.resources["ground"].spritesheet.animations[tileData.ground],
			// this.resources["ground"].spritesheet.animations["water"],
			undefined,
			undefined,
		];

		if (tileData.ground === "bridge1") {
			textures[0] = [this.resources["ground"].spritesheet.animations["bridge"][0]]
		}
		if (tileData.ground === "bridge_left") {
			textures[0] = [this.resources["ground"].spritesheet.animations["bridge"][1]]

		}
		if (tileData.ground === "bridge_middle") {
			textures[0] = [this.resources["ground"].spritesheet.animations["bridge"][2]]

		}
		if (tileData.ground === "bridge_right") {
			textures[0] = [this.resources["ground"].spritesheet.animations["bridge"][3]]

		}
		if (tileData.ground === "water") {
			textures[0] = this.resources["ground"].spritesheet.animations["water"];
		} else if (
			tileData.ground === "grass" ||
			tileData.ground === "water" ||
			tileData.ground === "dry"
		) {
			textures[0] = [
				this.resources["ground"].spritesheet.animations[tileData.ground][
				Math.floor(
					Math.random() *
					this.resources["ground"].spritesheet.animations[tileData.ground]
						.length
				)
				],
			];
		}
		this.sprites[1].canBeTransparent = false;
		if (tileData.plant === "tree") {
			this.sprites[1].canBeTransparent = true;

			// textures[1].canBeTransparent = true;

			// textures[1] = new PIXI.Texture.from("assets/Tiles/TreeModule.png")
			textures[1] = this.resources["tree"].spritesheet.animations["tree"];
			this.sprites[1].anchor.set(0.5, 0.75);
		} else if (tileData.plant === "fertilizer") {
			textures[1] = this.resources["item"].spritesheet.animations["fertilizer"];
		} else if (tileData.plant === "berry") {
			textures[1] = this.resources["item"].spritesheet.animations["berry"];
		} else if (tileData.plant === "bush") {
			// textures[1] = this.resources["item"].spritesheet.animations["bush"][0]
			textures[1] = this.resources["item"].spritesheet.animations["bush"];
			// [
			// Math.floor(Math.random() * this.resources["item"].spritesheet.animations["bush"].length)
			// ]
		} else if (tileData.plant === "flower") {
			// textures[1] = this.resources["item"].spritesheet.animations["bush"][0]
			textures[1] = this.resources["item"].spritesheet.animations["flower"];
			// [
			// Math.floor(Math.random() * this.resources["item"].spritesheet.animations["flower"].length)
			// ]
		}

		if (tileData.animals === "frog") {
			textures[2] = this.resources["animal"].spritesheet.animations["frog"];
		} else if (tileData.animals === "wh_butter") {
			textures[2] =
				this.resources["animal"].spritesheet.animations["whiteButterfly"];
		} else if (tileData.animals === "yl_butter") {
			textures[2] =
				this.resources["animal"].spritesheet.animations["yellowButterfly"];
		} else if (tileData.animals === "bee") {
			textures[2] = this.resources["animal"].spritesheet.animations["bee"];
		}

		for (let i = 0; i < 3; i++) {
			this.sprites[i].x = this.position.x * spriteSize;
			this.sprites[i].y = this.position.y * spriteSize;

			this.sprites[i].visible = true;
			if (textures[i]) {
				this.sprites[i].textures = textures[i];
			} else this.sprites[i].visible = false;
		}
		if (tileData.ground === "water") {
			this.sprites[0].animationSpeed = 0.05;
			this.sprites[0].play();
		}
		this.sprites[0].texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
		this.sprites[1].texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
		this.sprites[2].texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;

		this.plant().zIndex = this.plant().y - spriteSize / 4;

		if (
			tileData.animals === "frog" ||
			tileData.animals === "wh_butter" ||
			tileData.animals === "yl_butter" ||
			tileData.animals === "bee"
		) {
			this.sprites[2].animationSpeed = 0.05;
			this.sprites[2].play();
		}

		this.plant().zIndex = this.plant().y - spriteSize / 4;

		this.ground().interactive = true;
		if ((tileData.ground === "water" && this.playerType !== "arroseur") || (tileData.ground === "cloud")) {
			// this.ground().play()
			this.ground().interactive = false;
		}
	}

	updateAlpha({ player }) {
		this.plant().alpha = 1;
		// if (this.plant().getBounds().intersects(player.getBounds()) && this.plant().canBeTransparent === true)
		if (this.plant().canBeTransparent === true
			&& this.plant().y > player.y && this.plant().y - 100 < player.y
			&& this.plant().x - 30 < player.x && this.plant().x + 30 > player.x)
			this.plant().alpha = 0.5;
	}
}

class Chunck {
	constructor({ position, size, container, onClic, resources, playerType }) {
		this.tiles = new Array(size);
		this.size = size;
		this.position = position;

		for (let x = 0; x < size; x++) {
			this.tiles[x] = new Array(size);

			for (let y = 0; y < size; y++) {
				let tile = new Tile({
					position: {
						x: 0,
						y: 0,
					},
					container: container,
					onClic: onClic,
					resources: resources,
					playerType: playerType,
				});
				this.tiles[x][y] = tile;
			}
		}
	}

	updateTexture({ chunckData, chunckPos }) {
		this.position = chunckPos;
		for (let x = 0; x < this.size; x++) {
			for (let y = 0; y < this.size; y++) {
				this.tiles[x][y].updateTexture({
					tileData: chunckData[x][y],
					tilePos: {
						x: this.position.x * this.size + x,
						y: this.position.y * this.size + y,
					},
				});
			}
		}
	}

	updateAlpha({ player }) {
		for (let x = 0; x < this.size; x++) {
			for (let y = 0; y < this.size; y++)
				this.tiles[x][y].updateAlpha({ player: player });
		}
	}

	updateTile({ tileData, tilePos }) {
		this.tiles[tilePos.x][tilePos.y].updateTexture({ tileData: tileData });
	}
}

class Map {
	/*
	 **	chunckSize:	chunck size in tile
	 **	nbChunck:	number of chunck to charge
	 **	container:	the container where all the sprite will be added
	 **	data:		the map array
	 **	ressurces:	loader resources
	 **	onClic:		function which will be launch when a tile is clic
	 */
	constructor({
		chunckSize,
		nbChunck,
		container,
		data,
		resources,
		onClic,
		playerType,
	}) {
		this.chunckSize = chunckSize;
		this.tree = 0;
		this.nbAnimals = 0;
		this.nbTotaltree = countNbTotalTree({ mapData: data });
		this.nbChunck = nbChunck;
		this.playerType = playerType;
		this.mapSizeTile = Math.sqrt(nbChunck) * chunckSize;
		this.mapSizeChunck = this.mapSizeTile / this.chunckSize;

		this.totalMapSizeChunck = {
			x: Math.floor(data[0].length / this.chunckSize),
			y: Math.floor(data.length / this.chunckSize),
		};
		this.totalMapSizeTile = { x: data[0].length, y: data.length };

		this.resources = resources;
		this.onClic = onClic;

		this.container = container;
		this.data = this.shallowCopyMap({ data: data });
		this.chuncks = this.createChuncks();
		this.loadedChuncks = new Array(nbChunck);
	}

	shallowCopyMap({ data }) {
		let mapData = [];
		for (let chunck_x = 0; chunck_x < this.totalMapSizeChunck.x; chunck_x++) {
			mapData.push([]);
			for (let chunck_y = 0; chunck_y < this.totalMapSizeChunck.y; chunck_y++) {
				let chunck = [];
				for (let x = 0; x < this.chunckSize; x++) {
					chunck.push([]);
					for (let y = 0; y < this.chunckSize; y++) {
						chunck[x].push(
							data[chunck_x * this.chunckSize + x][
							chunck_y * this.chunckSize + y
							]
						);
					}
				}

				mapData[chunck_x].push(chunck);
			}
		}
		return mapData;
	}

	createChuncks() {
		let map = new Array(this.nbChunck);

		for (let i = 0; i < map.length; i++) {
			map[i] = new Chunck({
				size: this.chunckSize,
				container: this.container,
				position: { x: 0, y: 0 },
				onClic: this.onClic,
				resources: this.resources,
				playerType: this.playerType,
			});
		}
		return map;
	}

	chunckId(chunckPos) {
		return chunckPos.x * 1000 + chunckPos.y;
	}

	chunckPos(chunckId) {
		return { x: Math.floor(chunckId / 1000), y: chunckId % 1000 };
	}

	/*
	 **	update which chuncks are loaded with the player position in pixel
	 */
	updateChuncks({ player }) {
		// retrieve the chunk on which the player is located
		let playerPosTile = {
			x: Math.floor(player.x / spriteSize),
			y: Math.floor(player.y / spriteSize),
		};
		let playerPosChunck = {
			x: Math.floor(playerPosTile.x / this.chunckSize),
			y: Math.floor(playerPosTile.y / this.chunckSize),
		};

		// retrieve the list of the chuncks around the player
		let newChuncks = [];
		let X = playerPosChunck.x - (this.mapSizeChunck - 1) / 2;
		let Y = playerPosChunck.y - (this.mapSizeChunck - 1) / 2;
		for (let y = 0; y < this.mapSizeChunck; y++) {
			for (let x = 0; x < this.mapSizeChunck; x++) {
				let chunckPos = { x: X + x, y: Y + y };
				if (chunckPos.x >= 0 && chunckPos.y >= 0)
					newChuncks.push(this.chunckId(chunckPos));
			}
		}

		// find out which are the new chuncks to load among the new ones and which are the chuncks to unload among the current ones
		let chuncksToLoad = newChuncks.filter(
			(value) => !this.loadedChuncks.includes(value)
		);
		let chuncksToUnload = this.loadedChuncks.filter(
			(value) => !newChuncks.includes(value)
		);

		// console.log("loadedChuncks",this.loadedChuncks)
		// console.log("New Chuncks", newChuncks)
		// console.log("Chunck to load", chuncksToLoad)
		// console.log("Chunck to unload", chuncksToUnload)

		// reload new chuncks with the unloaded ones
		for (let i = 0; i < chuncksToLoad.length; i++) {
			let chunckIndex;
			if (chuncksToUnload.length !== 0)
				chunckIndex = this.loadedChuncks.findIndex(
					(value) => value === chuncksToUnload[i]
				);
			else
				chunckIndex = this.loadedChuncks.findIndex(
					(value) => value === undefined
				);
			let chunckPos = this.chunckPos(chuncksToLoad[i]);
			if (
				chunckPos.x >= this.totalMapSizeChunck.x ||
				chunckPos.y >= this.totalMapSizeChunck.y
			)
				continue;
			this.chuncks[chunckIndex].updateTexture({
				chunckData: this.data[chunckPos.x][chunckPos.y],
				chunckPos: chunckPos,
			});
			this.loadedChuncks[chunckIndex] = chuncksToLoad[i];
		}
	}

	updateAlpha({ player }) {
		for (let i = 0; i < this.chuncks.length; i++)
			this.chuncks[i].updateAlpha({ player: player });
	}

	getTile({ x, y }) {
		let chunck_x = Math.floor(x / this.chunckSize);
		let chunck_y = Math.floor(y / this.chunckSize);

		let chunckIndex = -1;
		for (let i = 0; i < this.nbChunck; i++) {
			if (
				this.chuncks[i].position.x == chunck_x &&
				this.chuncks[i].position.y == chunck_y
			)
				chunckIndex = i;
		}
		if (chunckIndex == -1) return false;

		let tile_x = x % this.chunckSize;
		let tile_y = y % this.chunckSize;

		return this.chuncks[chunckIndex].tiles[tile_x][tile_y];
	}

	/*
	 **	update tile texture with it's position in pixel
	 */
	// updateTile({ tileData, tilePos }) {
	// 	// retrieve the chunk on which the tile is located
	// 	let tilePosChunck = { x: tilePos.x / this.chunckSize, y: tilePos.y / this.chunckSize }
	// 	let chunckIndex = tilePosChunck.x + (tilePosChunck.y * this.totalMapSizeChunck)

	// 	// retrieve the tile index inside of it's chunck
	// 	let chunckPosPixel = { x: this.chunck[chunckIndex].x, y: this.chunck[chunckIndex].y }
	// 	let chunckPosTile = { x: chunckPosPixel.x / spriteSize, y: chunckPosPixel.y / spriteSize }
	// 	let tileIndex = tilePosTile.x - chunckPosTile.x + ((tilePosTile.y - chunckPosTile.y) * this.chunckSize)

	// 	// retrieve the chunck container that correspond to the one were the tile is located
	// 	let chunckContainer
	// 	for (const chunck in this.chunck) {
	// 		if (chunck.x === chunckPosPixel.x && chunck.y === chunckPosPixel.y)
	// 			chunckContainer = chunck
	// 	}

	// 	// update texture
	// 	this.chunck[chunckIndex].tiles[tilePos.x][tilePos.y].updateTexture({ tileData: tileData })
	// }
}

export default Map;
