import * as PIXI from "pixi.js";
import { Astar, Astar_init } from "./aStart";
import { tileToUpdate } from "./robotManager.js"
import { edit } from "./edit.js"

class Robot extends PIXI.AnimatedSprite {
	constructor(
		spritesheet,
		isPlayer = false,
		direction = "iddle_down",
		needItem = false,
		type,
		position = { x: 0, y: 0 },
		name = "Bobby",
		mapData,
	) {
		super(spritesheet.animations[direction]);
		this.mapData = mapData;
		this.isPlayer = isPlayer;
		this.type = type;
		this.way = [];
		this.index = 0;
		this.hasItem = false;
		this.needItem = needItem;
		this.isWorking = false;
		this.begin = true;
		this.capacity = 0;
		this.robotName = name;
		this.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
		this.width = 60;
		this.height = 60;
		this.position.set(position.x * 60, position.y * 60);
		this.destinationX = 0;
		this.destinationY = 0;
		this.onWater = false;
		this.anchor.set(0.5, 0.75);
		this.characterAnimations = {
			spritesheet,
			direction,
		};
		this.animationSpeed = 0.167;
		this.play();
}

	moveTo({ x, y, tiles }) {
		this.way = null;
		this.destinationX = x;
		this.destinationY = y;

		let currentPosX;
		let currentPosY;
		if (this.x < x * 60) currentPosX = Math.floor(this.x / 60);
		else currentPosX = Math.ceil(this.x / 60);

		if (this.y < y * 60) currentPosY = Math.floor(this.y / 60);
		else currentPosY = Math.ceil(this.y / 60);

		Astar_init({
			posDepart: { x: currentPosX, y: currentPosY },
			posFin: { x, y },
		});

		while (this.way == null) {
			this.index = 0;
			this.updateTile = false;
			// this.way = wayGenerator({player: this, x, y});
			this.way = Astar({
				posDepart: { x: currentPosX, y: currentPosY },
				posFin: { x, y },
				tiles: tiles,
				type: this.type,
			});
		}
	}

	switchAnimation(animation) {
		if (this.way && this.index === this.way.length) {
			animation = `iddle_${animation}`;
		}
		if (!this.needItem && this.type !== "laboureur") {
			animation = `carrying_${animation}`;
		}
		if (this.onWater) {
			animation = `bouee_${animation}`;
		}

		if (this.isWorking) {
			animation = "action";
			this.loop = false;
			setTimeout(() => {
				this.isWorking = false;
				this.loop = true;
			}, 1000);
		}
		if (
			this.characterAnimations.spritesheet.animations[animation] &&
			animation !== this.characterAnimations.direction
		) {
			this.characterAnimations.direction = animation;
			this.textures =
				this.characterAnimations.spritesheet.animations[animation];
			this.play();
		}
	}

	updatePlayer({ onEdit, map, mapData, resources }) {
		setInterval(() => {
			if (this.way != null && this.index < this.way.length) {
				let animation = this.characterAnimations.direction;
				if (this.x > this.way[this.index].x * 60) {
					this.x--;
					animation = "left";
				}
				if (this.x < this.way[this.index].x * 60) {
					this.x++;
					animation = "right";
				}
				if (this.y > this.way[this.index].y * 60) {
					this.y--;
					animation = "up";
				}
				if (this.y < this.way[this.index].y * 60) {
					this.y++;
					animation = "down";
				}
				if (
					this.x === this.way[this.index].x * 60 &&
					this.y === this.way[this.index].y * 60
				) {
					if (this.type == "arroseur" && this.mapData[this.way[this.index].x][this.way[this.index].y].ground == "water")
						this.onWater = true;
					else
						this.onWater = false;

					this.index++;
				}
				this.zIndex = this.y;
				this.switchAnimation(animation);
			}
			if ((this.way != null &&
				this.way.length > 0 &&
				this.way.length == this.index &&
				this.x === this.destinationX * 60 &&
				this.y === this.destinationY * 60) || this.begin == true) {
				this.begin = false;
				let tile = edit({ player: this, x: Math.floor(this.x / 60), y: Math.floor(this.y / 60), mapData, map });
				if (tile)
					onEdit({ tile, name: this.robotName, type: this.type });
				this.way = null;
			}
			// if (this.isPlayer && map.nbTotaltree - map.tree > 5 && tileToUpdate({ robot: this, mapData }) == null) {
			// 	console.log("OMG ON CHANGE DE PERSO")
			// 	switch (this.type) {
			// 		case "laboureur":
			// 			this.type = "planteur";
			// 			break;
			// 		case "planteur":
			// 			this.type = "arroseur";
			// 			break;
			// 		case "arroseur":
			// 			this.type = "fertilizer";
			// 			break;
			// 		default:
			// 			break;
			// 	}
			// 	this.characterAnimations = {
			// 		spritesheet: resources[this.type].spritesheet,
			// 		direction: "iddle_down",
			// 	};
			// }
		}, 15);
	}
}

export default Robot;
