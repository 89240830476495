let mapWidth = 30;
let mapHeight = 30;
let gScore = createArray(mapWidth, mapHeight);
let fScore = createArray(mapWidth, mapHeight);
let Q = createArray(mapWidth, mapHeight);
let predecesseur = createArray(mapWidth, mapHeight);
let cheminTrouve;
let openSet = [];

function Dijkstra_poids(x1, y1, x2, y2, tiles, type) {
	// retourne un "poids" entre deux points
	// remarque : dans un jeu, on pourrait mettre un poids plus important s'il y a une colline à franchir par exemple.
	if ((tiles[x2][y2].ground === "water" && type !== "arroseur") || (tiles[x2][y2].ground === "cloud")) {
		return 99999;
	} else if (tiles[x2][y2].plant === "tree") {
		return 2;
	} else {
		if (x1 === x2 || y1 === y2) {
			// si c'est une case adjacente, on met un poids de 1
			return 1;
		} else {
			// si c'est une diagonale, on met un poids de 1.5 pour favoriser le chemin le plus droit
			return 1.5;
		}
	}
}

function createArray(length) {
	// fonction de création d'un tableau à n dimensions
	var arr = new Array(length || 0),
		i = length;

	if (arguments.length > 1) {
		var args = Array.prototype.slice.call(arguments, 1);
		while (i--) arr[length - 1 - i] = createArray.apply(this, args);
	}

	return arr;
}

function enrCoord(xVal, yVal, array) {
	// enregistrement d'un tableau de coordonnées x,y
	array.push({ x: xVal, y: yVal });
}

export function Astar_init({ posDepart, posFin }) {
	cheminTrouve = false;
	for (let i = 0; i < mapWidth; i++) {
		for (let j = 0; j < mapHeight; j++) {
			gScore[i][j] = 99999;
			fScore[i][j] = 99999;
			Q[i][j] = true;
		}
	}
	gScore[posDepart.x][posDepart.y] = 0;
	fScore[posDepart.x][posDepart.y] = Astar_h({ x1: posDepart.x, y1: posDepart.y, x2: posFin.x, y2: posFin.y });
	predecesseur = createArray(mapWidth, mapHeight);
	while (openSet.length > 0) { openSet.pop(); }
	enrCoord(posDepart.x, posDepart.y, openSet);
}

function Astar_h({ x1, y1, x2, y2 }) {
	// fonction heuristique qui retourne la distance entre un point x,y et l'arrivée
	// j'utilise pythagore pour calculer la distance entre ces deux points
	return Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2);
}

function Astar_recherche() {
	let mini = 99999;
	let sommetx, sommety, indice;
	sommetx = -1;
	sommety = -1;
	indice = -1;
	// recherche dans openSet du plus petit fScore
	for (let i = 0; i < openSet.length; i++) {
		if (fScore[openSet[i].x][openSet[i].y] < mini) {
			mini = fScore[openSet[i].x][openSet[i].y];
			sommetx = openSet[i].x;
			sommety = openSet[i].y;
			indice = i;
		}
	}
	return { x: sommetx, y: sommety, i: indice };
}

function Astar_paspresent(x, y) {
	let paspresent = true;
	for (let i = 0; i < openSet.length; i++) {
		if (openSet[i].x === x && openSet[i].y === y) { paspresent = false; }
	}
	return paspresent;
}

export function Astar({ posDepart, posFin, tiles, type }) {
	let current;
	let tentative_gScore;
	let curX, curY;

	if (openSet.length > 0 && !cheminTrouve) {
		current = Astar_recherche();
		if (current.x !== -1) {
			// on a trouvé la fin ?
			if (current.x === posFin.x && current.y === posFin.y) {
				// on reconstitue le chemin
				curX = posFin.x; curY = posFin.y;
				cheminTrouve = true;
				let way = [];
				while (!(curX === posDepart.x && curY === posDepart.y)) {
					way.unshift({ x: curX, y: curY });
					let tmp = curX;
					curX = predecesseur[curX][curY].x;

					curY = predecesseur[tmp][curY].y;
				}
				// on vide openSet, c'est fini
				while (openSet.length > 0) { openSet.pop(); }
				return way;
			}
			// on enlève cet élément des élements à traiter
			openSet.splice(current.i, 1);
			// on teste toutes les cases autour
			for (let i = -1; i <= 1; i++) {
				for (let j = -1; j <= 1; j++) {
					if (!(i === 0 && j === 0) && current.x + i >= 0 && current.x + i < mapWidth && current.y + j >= 0 && current.y + j < mapHeight) {
						tentative_gScore = gScore[current.x][current.y] + Dijkstra_poids(current.x, current.y, current.x + i, current.y + j, tiles, type);
						if (tentative_gScore < gScore[current.x + i][current.y + j]) {
							predecesseur[current.x + i][current.y + j] = { x: current.x, y: current.y };
							gScore[current.x + i][current.y + j] = tentative_gScore;
							fScore[current.x + i][current.y + j] = gScore[current.x + i][current.y + j] + Astar_h({ x1: current.x + i, y1: current.y + j, x2: posFin.x, y2: posFin.y });
							if (Astar_paspresent(current.x + i, current.y + j)) {
								// on ajoute un nouveau noeud à découvrir
								enrCoord(current.x + i, current.y + j, openSet);
								// zone explorée, sert juste à l'affichage
								Q[current.x + i][current.y + j] = false;
							}
						}
					}
				}
			}
		}
	}
	return null;
}