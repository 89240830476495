import { findRandomGrassTile } from "./random";

export function edit({ player, x, y, mapData, map }) {
	let tile = mapData[x][y];
	let needToUpdate = false;
	let tileEdited = undefined;

	switch (player.type) {
		case "laboureur":
			switch (tile.ground) {
				case "dry":
					tile.ground = "plowed";
					needToUpdate = true;
					tileEdited = "dry";
					player.isWorking = true;
					break;
			}
			break;
		case "planteur":
			switch (tile.ground) {
				case "plowed":
					if (player.capacity > 0) {
						player.capacity -= 1;
						tile.ground = "seeded";
						tile.plant = "plant";
						tileEdited = "plowed";
						needToUpdate = true;
						player.isWorking = true;
					}
					break;
				case "grass":
					if (tile.plant === "berry" && player.capacity <= 5) {
						player.capacity = 5;
						tile.plant = "none";
						findRandomGrassTile({ mapData, map, new_type: "berry" })
						tileEdited = "berry";
						needToUpdate = true;
					}
					break;
			}
			break;
		case "arroseur":
			switch (tile.ground) {
				case "seeded":
					if (player.capacity > 0) {
						player.capacity -= 1;
						tile.ground = "watered";
						tile.plant = "watered_plant"
						tileEdited = "seeded";
						player.isWorking = true;
						needToUpdate = true;
					}
					break;
				case "water":
					if (player.capacity <= 5) {
						player.capacity = 5;
						tileEdited = "water";
					}
					break;
			}
			break;
		case "fertilizer":
			switch (tile.ground) {
				case "watered":
					if (player.capacity > 0) {
						player.capacity -= 1;
						map.tree++;
						tile.ground = "tree";
						tile.plant = "tree";
						player.isWorking = true;
						needToUpdate = true;
						tileEdited = "watered";
					}
					break;
				case "grass":
					if (tile.plant === "fertilizer" && player.capacity <= 5) {
						player.capacity = 5;
						findRandomGrassTile({ mapData, map, new_type: "fertilizer" })
						tile.plant = "none";
						tileEdited = "fertilizer";
						needToUpdate = true;
					}
					break;
			}
			break;
	}
	if (player.capacity === 5)
		player.needItem = false;
	else if (player.capacity === 0)
		player.needItem = true;
	if (needToUpdate === true) {
		player.switchAnimation('action');
		let tile_to_update = map.getTile({ x: x, y: y });
		if (tile_to_update !== false) {
			console.log("Update texture freeze ? ")

			tile_to_update.updateTexture({ tileData: tile, tilePosition: tile_to_update.position })
		}
	}

	return (tileEdited);

}