
export function updateAnimal({ mapData, map }) {
	console.log("ANIMALS UPDATED OMG", map.nbAnimals);
	while (map.nbAnimals < map.tree + 10) {
		if (map.nbAnimals % 4 === 0)
			findRandomTile({ mapData, map, new_animal: "frog" });
		else if (map.nbAnimals % 4 === 1)
			findRandomTile({ mapData, map, new_animal: "bee" });
		else if (map.nbAnimals % 4 === 2)
			findRandomTile({ mapData, map, new_animal: "yl_butter" });
		else
			findRandomTile({ mapData, map, new_animal: "wh_butter" });
		map.nbAnimals++;
	}
}

function findRandomTile({ mapData, map, new_animal }) {
	let x = Math.floor(Math.random() * (mapData.length + 1));
	let y = Math.floor(Math.random() * (mapData.length + 1));

	let numberOfTurns = 0

	for (let i = y; i < mapData.length; i++) {
		for (let j = x; j < mapData.length; j++) {
			if (new_animal == "bee") {
				if (mapData[i][j].ground === "tree" && mapData[i][j].animals === "none")
					return (changeTile({ i, j, mapData, new_type: new_animal, map }));
			} else if (new_animal == "frog") {
				if (mapData[i][j].ground === "grass" && mapData[i][j].animals === "none")
					return (changeTile({ i, j, mapData, new_type: new_animal, map }))
			} else {
				if (mapData[i][j].animals === "none")
					return (changeTile({ i, j, mapData, new_type: new_animal, map }))
			}
			if (i == mapData.length - 1 && j == mapData.length - 1) {
				if (numberOfTurns > 1) {
					return (null);
				}
				i = 0;
				j = 0;
				x = 0;
				numberOfTurns++;
			}
		}
	}
	return { x, y };
}

function changeTile({ i, j, mapData, new_type, map }) {
	mapData[i][j].animals = new_type;
	let tile_to_update = map.getTile({ x: i, y: j });
	if (tile_to_update !== false)
		tile_to_update.updateTexture({ tileData: mapData[i][j], tilePosition: tile_to_update.position })
	return ({ i, j });
}