import React, { useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

function Login({ setUser, user }) {
  const history = useNavigate();
  const handleLogin = ({ uuid, login }) => {
    // setUser({ uuid: "1", login: "juthomas" });
    setUser({ uuid, login });
    history("/game");
  };
  const handleLogout = () => setUser(null);

  function login(e) {
    e.preventDefault();
    console.log("Event");
    const username = Object.fromEntries(new FormData(e.target))["username"];

    if (username.length > 0) {
      console.log("Clicked");

      //Faire une requette au serveur ici pour chopper un UUID si on entre un login
      handleLogin({ uuid: 42, login: username });
    } else {
      // document.getElementById("Username").classList.add("error");
      handleLogout();
    }
  }

  return (
    <div
      id="login"
      className="div_login loginClass transformSlow"
      style={{ zIndex: 10001 }}
    >
      <div className="div_login inputs transform">
        <h1 id="title_login">One For Tree !</h1>
        <form className="connection-form" id="connectionForm" onSubmit={login}>
          <input
            className="input_login"
            type="text"
            name="username"
            placeholder="Username"
          />
          <input className="input_login" type="submit" value="PLAY !" />
        </form>
      </div>
    </div>
  );
}

export default Login;
