import * as PIXI from "pixi.js";
import Robot from "./robot";
import Map from "./chunckingMap";
import mapData from "../../xpm_to_json/map30_2.json";
import { edit } from "./edit.js"
import { updateAnimal } from "./animalsManager";
import { generateRobot } from "./robotManager.js"
import { generateRandomType, generateRandomPos } from "./random.js"

export function mainGame({ viewport, resources, onPlayerChange, onEdit, user }) {
	console.log("OMG C CHARGER LOL");

	let container = new PIXI.Container();
	let type = generateRandomType({ index: Math.floor(Math.random() * 4), playerType: null, nbRobot: 0 });
	let player = new Robot(resources[type].spritesheet, true, "down", true, type, generateRandomPos({ mapData }), user.login, mapData);

	let map = new Map({
		chunckSize: 5, // needs to be odd
		nbChunck: 25, // it's square root needs to be odd
		data: mapData,
		resources: resources,
		container: container,
		onClic: ({ x, y }) => {
			player.moveTo({ x, y, tiles: mapData })
		},
		playerType: player.type
	});

	container.addChild(player);
	generateRobot({ nbRobot: 10, mapData, resources, container, map, player, onEdit });
	player.updatePlayer({ onEdit, map, mapData, resources });

	setInterval(() => {
		map.updateChuncks({ player: player });
		updateAnimal({ mapData, map })
	}, 1000);

	setInterval(() => {
		map.updateAlpha({ player: player });
		if (player.updateTile === true) {
			let tile = edit({ player, x: Math.floor(player.x / 60), y: Math.floor(player.y / 60), mapData, map });
			if (tile) {
				onEdit({ tile, name: player.robotName, type: player.type });
			}
			player.updateTile = false;
		}
		onPlayerChange({ player, co2: Math.round(100 - (map.tree / map.nbTotaltree * 100)) })
	}, 100);

	viewport.addChild(container);
	viewport.follow(player, {
		speed: null, // speed to follow in pixels/frame (0=teleport to location)
		acceleration: null, //0.05, // set acceleration to accelerate and decelerate at this rate; speed cannot be 0 to use acceleration
		radius: 0, // radius (in world coordinates) of center circle where movement is allowed without moving the viewport
	});
	return player;
}