import * as PIXI from "pixi.js";
export function loadTextures({ loader }) {
  loader.add("laboureur", "assets/players/laboureur/laboureur.json");
  loader.add("fertilizer", "assets/players/fertilizer/fertilizer.json");
  // loader.add("arroseur", "assets/players/arroseur/arroseur.json");
  loader.add("arroseur", "assets/SpriteSets/arroseur.json");

  loader.add("planteur", "assets/players/planteur/planteur.json");
  // loader.add("ground", "assets/ground/GroundSet.json");
  loader.add("ground", "assets/SpriteSets/GroundSet.json");
  loader.add("tree", "assets/SpriteSets/TreeSet.json");
  loader.add("item", "assets/SpriteSets/ItemSet.json");
  loader.add("animal", "assets/SpriteSets/AnimalSet.json");
}
