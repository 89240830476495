// import { Viewport } from 'pixi-viewport' // use with modern build toolchain

// import { stars } from './stars'
// import * as target from './target'
// import * as PIXI from "pixi.js";
// import * as pixi_viewport from './viewport.min.js'
import { Viewport } from "pixi-viewport";

// const WORLD_WIDTH = 2000
// const WORLD_HEIGHT = 2000
// const STAR_SIZE = 30
// const BORDER = 10

// INSTRUCTIONS
// see https://davidfig.github.io/pixi-viewport/jsdoc/ for full API documentation
// uncomment out the plugins or change options to experiment with how the viewport works
let viewport;

//Functions added to window to get if the current device is a mobile or tablet
window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export function create(renderer) {
  // create the viewport
  // viewport = new Viewport({    // use with modern build toolchain
  viewport = new Viewport({
    // screenWidth: window.innerWidth,              // screen width used by viewport (eg, size of canvas)
    // screenHeight: window.innerHeight,            // screen height used by viewport (eg, size of canvas)
    //worldWidth: WORLD_WIDTH,                        // world width used by viewport (automatically calculated based on container width)
    //worldHeight: WORLD_HEIGHT,                      // world height used by viewport (automatically calculated based on container height)
    // threshold: 5,                                // number of pixels to move to trigger an input event (e.g., drag, pinch) or disable a clicked event
    passiveWheel: false, // whether the 'wheel' event is set to passive (note: if false, e.preventDefault() will be called when wheel is used over the viewport)
    // stopPropagation: false,                      // whether to stopPropagation of events that impact the viewport (except wheel events, see options.passiveWheel)
    // forceHitArea: null,                          // change the default hitArea from world size to a new value
    // noTicker: false,                             // set this if you want to manually call update() function on each frame
    // ticker: PIXI.Ticker.shared,                  // use this PIXI.ticker for updates
    interaction: renderer.plugins.interaction, // InteractionManager, available from instantiated WebGLRenderer/CanvasRenderer.plugins.interaction - used to calculate pointer position relative to canvas location on screen
    // divWheel: null,                              // div to attach the wheel event (uses document.body as default)
    // disableOnContextMenu: false,                 // remove oncontextmenu=() => {} from the divWheel element
  });

  viewport
    .drag({
      direction: "none", // (x, y, or all) direction to drag
      // pressDrag: true,                 // whether click to drag is active
      // wheel: false,                     // use wheel to scroll in direction (unless wheel plugin is active)
      // wheelScroll: 1,                  // number of pixels to scroll with each wheel spin
      // reverse: false,                  // reverse the direction of the wheel scroll
      // clampWheel: false,               // clamp wheel (to avoid weird bounce with mouse wheel)
      // underflow: 'center',             // (top-left, top-center, etc.) where to place world if too small for screen
      // factor: 1,                       // factor to multiply drag to increase the speed of movement
      // mouseButtons: 'all',             // changes which mouse buttons trigger drag, use: 'all', 'left', right' 'middle', or some combination, like, 'middle-right'; you may want to set viewport.options.disableOnContextMenu if you want to use right-click dragging
      // keyToPress: null,                // array containing https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code codes of keys that can be pressed for the drag to be triggered, e.g.: ['ShiftLeft', 'ShiftRight'}
      // ignoreKeyToPressOnTouch: false,  // ignore keyToPress for touch events
      // lineHeight: 20,                  // scaling factor for non-DOM_DELTA_PIXEL scrolling events (used for firefox mouse scrolling)
    })
    .decelerate({
      // friction: 0.95,              // percent to decelerate after movement
      // bounce: 0.8,                 // percent to decelerate when past boundaries (only applicable when viewport.bounce() is active)
      // minSpeed: 0.01,              // minimum velocity before stopping/reversing acceleration
    })
    .pinch({
      // noDrag: false,               // disable two-finger dragging
      // percent: 1,                  // percent to modify pinch speed
      // factor: 1,                   // factor to multiply two-finger drag to increase the speed of movement
      // center: null,                // place this point at center during zoom instead of center of two fingers
      axis: "all", // axis to zoom
    })
    .wheel({
      // percent: 0.1,                // smooth the zooming by providing the number of frames to zoom between wheel spins
      // interrupt: true,             // stop smoothing with any user input on the viewport
      // reverse: false,              // reverse the direction of the scroll
      // center: null,                // place this point at center during zoom instead of current mouse position
      // lineHeight: 20,	            // scaling factor for non-DOM_DELTA_PIXEL scrolling events
      axis: "all", // axis to zoom, all none
    });

  // viewport.bounce({
  //     sides: 'all',                // all, horizontal, vertical, or combination of top, bottom, right, left(e.g., 'top-bottom-right')
  //     friction: 0.5,               // friction to apply to decelerate if active
  //     time: 150,                   // time in ms to finish bounce
  //     bounceBox: null,             // use this bounceBox instead of { x: 0, y: 0, width: viewport.worldWidth, height: viewport.worldHeight }
  //     ease: 'easeInOutSine',       // ease function or name (see http://easings.net/ for supported names)
  //     underflow: 'center',         // (top/bottom/center and left/right/center, or center) where to place world if too small for screen
  // })

  // viewport.animate({
  //     time: 1000,                     // time to animate
  //     position: null,                 // position to move viewport
  //     width: null,                    // desired viewport width in world pixels (use instead of scale; aspect ratio is maintained if height is not provided)
  //     height: null,                   // desired viewport height in world pixels(use instead of scale; aspect ratio is maintained if width is not provided)
  //     scale: null,                    // scale to change zoom(scale.x = scale.y)
  //     scaleX: null,                   // independently change zoom in x - direction
  //     scaleY: null,                   // independently change zoom in y - direction
  //     ease: 'linear',                 // easing function to use
  //     callbackOnComplete: null,       // callback when animate is complete
  //     removeOnInterrupt: false,	   // removes this plugin if interrupted by any user input
  // })

  // viewport.clamp({
  //     left: false,                // whether to clamp to the left and at what value
  //     right: false,               // whether to clamp to the right and at what value
  //     top: false,                 // whether to clamp to the top and at what value
  //     bottom: false,              // whether to clamp to the bottom and at what value
  //     direction: 'all',           // (all, x, or y) using clamps of [0, viewport.worldWidth / viewport.worldHeight]; replaces left / right / top / bottom if set
  //     underflow: 'center',	       // where to place world if too small for screen (e.g., top - right, center, none, bottomleft)
  // })

//   viewport.clampZoom({
//       // minWidth: null,                 // minimum width
//       // minHeight: null,                // minimum height
//       // maxWidth: null,                 // maximum width
//       // maxHeight: null,                // maximum height
//       minScale: 2.2,                 // minimum scale
//       maxScale: 3,                 // minimum scale
//   })

  // target.start()  // starts the target moving
  // viewport.follow(target.get(), {
  //     speed: 5,           // speed to follow in pixels/frame (0=teleport to location)
  //     acceleration: 0.05, // set acceleration to accelerate and decelerate at this rate; speed cannot be 0 to use acceleration
  //     radius: null,       // radius (in world coordinates) of center circle where movement is allowed without moving the viewport
  // })

  // viewport.mouseEdges({
  //     radius: null,           // distance from center of screen in screen pixels
  //     distance: 20,           // distance from all sides in screen pixels
  //     top: null,              // alternatively, set top distance (leave unset for no top scroll)
  //     bottom: null,           // alternatively, set bottom distance (leave unset for no top scroll)
  //     left: null,             // alternatively, set left distance (leave unset for no top scroll)
  //     right: null,            // alternatively, set right distance (leave unset for no top scroll)
  //     speed: 8,               // speed in pixels/frame to scroll viewport
  //     reverse: false,         // reverse direction of scroll
  //     noDecelerate: false,    // don't use decelerate plugin even if it's installed
  //     linear: false,          // if using radius, use linear movement (+/- 1, +/- 1) instead of angled movement (Math.cos(angle from center), Math.sin(angle from center))
  //     allowButtons: false,    // allows plugin to continue working even when there's a mousedown event
  // })

  // viewport.snap({
  //     topLeft: false,             // snap to the top-left of viewport instead of center
  //     friction: 0.8,              // friction/frame to apply if decelerate is active
  //     time: 1000,                 // time for snapping in ms
  //     ease: 'easeInOutSine',      // ease function or name (see http://easings.net/ for supported names)
  //     interrupt: true,            // pause snapping with any user input on the viewport
  //     removeOnComplete: false,    // removes this plugin after snapping is complete
  //     removeOnInterrupt: false,   // removes this plugin if interrupted by any user input
  //     forceStart: false,          // starts the snap immediately regardless of whether the viewport is at the desired location
  // })

  // viewport.snapZoom({
  //     width: 0,                   // the desired width to snap (to maintain aspect ratio, choose only width or height)
  //     height: 0,                  // the desired height to snap(to maintain aspect ratio, choose only width or height)
  //     time: 1000,                 // time for snapping in ms
  //     ease: 'easeInOutSine',      // ease function or name(see http://easings.net/ for supported names)
  //     center: null,               // place this point at center during zoom instead of center of the viewport
  //     interrupt: true,            // pause snapping with any user input on the viewport
  //     removeOnComplete: false,    // removes this plugin after snapping is complete
  //     removeOnInterrupt: false,   // removes this plugin if interrupted by any user input
  //     forceStart: false,          // starts the snap immediately regardless of whether the viewport is at the desired zoom
  //     noMove: false,              // zoom but do not move
  // })

  // create elements
  // stars(viewport, STAR_SIZE, BORDER)
  // target.setup(viewport)
  // border(viewport, BORDER)
  // fit and center the world into the panel
  // viewport.fit()
  // viewport.moveCenter(WORLD_WIDTH / 2, WORLD_HEIGHT / 2)
  // viewport.setZoom(1.00, true)
  if (window.mobileAndTabletCheck()) {
    viewport.setZoom(0.8, true);
    viewport.clampZoom({
        // minWidth: null,                 // minimum width
        // minHeight: null,                // minimum height
        // maxWidth: null,                 // maximum width
        // maxHeight: null,                // maximum height
        minScale: 1,                 // minimum scale
        maxScale: 3,                 // minimum scale
    })
  } else {
    viewport.setZoom(0.8, true);
    viewport.clampZoom({
        // minWidth: null,                 // minimum width
        // minHeight: null,                // minimum height
        // maxWidth: null,                 // maximum width
        // maxHeight: null,                // maximum height
        minScale: 2.2,                 // minimum scale
        maxScale: 3,                 // minimum scale
    })
  }
}

export function get() {
  return viewport;
}
